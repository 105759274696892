<template>
  <div class="manyManual drag" v-draw :id="dynamicID">
    <div class="evaluate-header">
      {{ titname }}
      <div class="evaluate-header-icon" @click="closeCompon">
        <img src="@/assets/images/close.png" alt="" />
      </div>
    </div>
    <div class="manual-con flex">
      <div class="manual_list" v-for="(item, index) in ManualList" :key="index">
        <div class="manual-con-tit">{{ item.name }}</div>
        <div class="manual-con-p">
          <el-slider
            v-model="item.manualP"
            :format-tooltip="formatTooltip"
            disabled
          ></el-slider>
          <div class="manual-con-text flex">
            <div>关</div>
            <div style="margin-left: auto">开</div>
          </div>
        </div>
        <div class="manual-con-o manual-con-p">
          <el-slider
            v-model="item.manualO"
            :format-tooltip="formatTooltip1"
            disabled
          ></el-slider>
          <div class="manual-con-text flex">
            <div>关</div>
            <div style="margin-left: auto">开</div>
          </div>
        </div>
        <div class="manual-con-type flex alicn-cen">
          <div class="type-p">
            <span class="">P</span>
            <span class="type-p-data">{{ item.num }}</span>
          </div>
          <div class="type-o">
            <span class="type-p-data">{{ item.num1 }}</span>
            <span class="">O</span>
          </div>
        </div>
        <div class="manual-con-btn flex">
          <div
            class="pzd"
            :class="{ oactive: 1 == infoList[item.node1].RM }"
            @click="isDisable ? downZ(1, item.node1) : ''"
          >
            自动
          </div>
          <div
            class="pzd"
            :class="{ oactive: 1 != infoList[item.node1].RM }"
            @click="isDisable ? downZ(0, item.node1) : ''"
          >
            手动
          </div>
        </div>
        <div class="manual-con-add flex">
          <div
            class="add-double-left"
            @mouseenter="enter(1, item.node1)"
            @mouseleave="leave(1, item.node1)"
            :style="currindex == 1 && str == item.node1 ? activStyle : ''"
            @click="
              isDisable
                ? numberjian(
                    5,
                    infoList[item.node1].AV,
                    1,
                    item.manualP,
                    item.manualO,
                    item.node1,
                    index
                  )
                : ''
            "
          >
            <span
              class="el-icon-caret-left"
              :style="currindex == 1 && str == item.node1 ? textcololr : ''"
            ></span>
            <span
              class="el-icon-caret-left el-icon-caret-left2"
              :style="currindex == 1 && str == item.node1 ? textcololr : ''"
            ></span>
          </div>
          <div class="add-double-cen flex align-cen">
            <div
              class="add-double-cen-btn"
              @mouseenter="enter(2, item.node1)"
              @mouseleave="leave(2, item.node1)"
              :style="currindex == 2 && str == item.node1 ? activStyle : ''"
              @click="
                isDisable
                  ? numberjian(
                      1,
                      infoList[item.node1].AV,
                      2,
                      item.manualP,
                      item.manualO,
                      item.node1,
                      index
                    )
                  : ''
              "
            >
              <span
                class="el-icon-caret-left"
                :style="currindex == 2 && str == item.node1 ? textcololr : ''"
              ></span>
            </div>
            <div
              class="add-double-cen-btn"
              @mouseenter="enter(3, item.node1)"
              @mouseleave="leave(3, item.node1)"
              :style="currindex == 3 && str == item.node1 ? activStyle : ''"
              @click="
                isDisable
                  ? numberAdd(
                      1,
                      infoList[item.node1].AV,
                      3,
                      item.manualP,
                      item.manualO,
                      item.node1,
                      index
                    )
                  : ''
              "
            >
              <span
                class="el-icon-caret-right"
                :style="currindex == 3 && str == item.node1 ? textcololr : ''"
              ></span>
            </div>
          </div>
          <div
            class="add-double-left add-double-right"
            @mouseenter="enter(4, item.node1)"
            @mouseleave="leave(4, item.node1)"
            :style="currindex == 4 && str == item.node1 ? activStyle : ''"
            @click="
              isDisable
                ? numberAdd(
                    5,
                    infoList[item.node1].AV,
                    4,
                    item.manualP,
                    item.manualO,
                    item.node1,
                    index
                  )
                : ''
            "
          >
            <span
              class="el-icon-caret-right el-icon-caret-left1"
              :style="currindex == 4 && str == item.node1 ? textcololr : ''"
            ></span>
            <span
              class="el-icon-caret-right"
              :style="currindex == 4 && str == item.node1 ? textcololr : ''"
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { downmanAction } from "@/api/index/index";
import { downAction } from "@/api/index/index";
export default {
  name: "manyManual",
  props: {
    infoList: {
      type: Object,
      default: {}, // 默认值
    },
    titname: [String, Number], //tit名字
    numM: [String, Number], //手操器数量
    mark: [String, Number], //不同变量mark
    manvar: [String, Number]
  },
  data() {
    return {
      dynamicID: "",
      ManualList: [],
      projectData: {},
      current: 0,
      isDisable: true,
      activStyle: "",
      currindex: "",
      str: "",
    };
  },
  watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
    titname: {
      handler(n, o) {
        this.titname = n;
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
    numM: {
      handler(n, o) {
        this.numM = n;
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
    mark: {
      handler(n, o) {
        this.mark = n;
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
    manvar: {
      handler(n, o) {
        this.manvar = n;
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  created() {
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));
    if (this.numM == 3 && this.titname == "MFL_MAN" + this.mark + "M") {
      this.dynamicID = "manyManual3";
      //    给煤手操器
      this.ManualList = [
        {
          manualP: parseInt(this.infoList["GMJ_" + this.mark + "_FV"]),
          num: this.infoList["GMJ_" + this.mark + "_FV"],
          mark: "GMJ_" + this.mark + "_FV",
          node: this.infoList["GMJ_" + this.mark + "_FV_node_string"],
          name: this.mark + "给煤手操器",
          manualO: parseInt(this.infoList["GMJ_MAN" + this.manvar].AV),
          num1: this.infoList["GMJ_MAN" + this.manvar].AV,
          mark1: "AV",
          node1: "GMJ_MAN" + this.manvar,
        },
        {
          manualP: parseInt(this.infoList["RFM_" + this.mark + "_FV"]),
          num: this.infoList["RFM_" + this.mark + "_FV"],
          mark: "RFM_" + this.mark + "_FV",
          node: this.infoList["RFM_" + this.mark + "_FV_node_string"],
          name: this.mark + "磨热风门手操器",
          manualO: parseInt(this.infoList["MFL_MAN" + this.manvar].AV),
          num1: this.infoList["MFL_MAN" + this.manvar].AV,
          mark1: "AV",
          node1: "MFL_MAN" + this.manvar,
        },
        {
          manualP: parseInt(this.infoList["LFM_" + this.mark + "_FV"]),
          num: this.infoList[["LFM_" + this.mark + "_FV"]],
          mark: "LFM_" + this.mark + "_FV",
          node: this.infoList["LFM_" + this.mark + "_FV_node_string"],
          name: this.mark + "磨冷风门手操器",
          manualO: parseInt(this.infoList["CKT_MAN" + this.manvar].AV),
          num1: this.infoList["CKT_MAN" + this.manvar].AV,
          mark1: "AV",
          node1: "CKT_MAN" + this.manvar,
        },
      ];
    } else if (this.numM == 2 && this.titname == "MFL_MANZR") {
      this.dynamicID = "manyManual2";
      this.ManualList = [
        {
          manualP: parseInt(this.infoList.ZRQT_A_FV),
          num: this.infoList.ZRQT_A_FV,
          mark: "ZRQT_A_FV",
          node: this.infoList.ZRQT_A_FV_node_string,
          name: "再热烟气温度手操器",
          manualO: parseInt(this.infoList.ZQT_MAN5.AV),
          num1: this.infoList.ZQT_MAN5.AV,
          mark1: "AV",
          node1: "ZQT_MAN5",
        },
        {
          manualP: parseInt(this.infoList.ZRQT_B_FV),
          num: this.infoList.ZRQT_B_FV,
          mark: "ZRQT_B_FV",
          node: this.infoList.ZRQT_B_FV_node_string,
          name: "再热蒸气量手操器",
          manualO: parseInt(this.infoList.ZQT_MAN6.AV),
          num1: this.infoList.ZQT_MAN6.AV,
          mark1: "AV",
          node1: "ZQT_MAN6",
        },
      ];
    } else if (this.numM == 2 && this.titname == "MFL_MANECFS") {
      this.dynamicID = "manyManual2";
      this.ManualList = [
        {
          manualP: parseInt(this.infoList.ECF_D_FV),
          num: this.infoList.ECF_D_FV,
          mark: "ECF_D_FV",
          node: this.infoList.ECF_D_FV_node_string,
          name: "D层周界风手操器",
          manualO: parseInt(this.infoList.ECF_MAN4.AV),
          num1: this.infoList.ECF_MAN4.AV,
          mark1: "AV",
          node1: "ECF_MAN4",
        },
        {
          manualP: parseInt(this.infoList.ECF_E_FV),
          num: this.infoList.ECF_E_FV,
          mark: "ECF_E_FV",
          node: this.infoList.ECF_E_FV_node_string,
          name: "E层周界风手操器",
          manualO: parseInt(this.infoList.ECF_MAN5.AV),
          num1: this.infoList.ECF_MAN5.AV,
          mark1: "AV",
          node1: "ECF_MAN5",
        },
      ];
    } else if (this.numM == 3 && this.titname == "MFL_MANECFX") {
      this.dynamicID = "manyManual3";
      this.ManualList = [
        {
          manualP: parseInt(this.infoList.ECF_A_FV),
          num: this.infoList.ECF_A_FV,
          mark: "ECF_A_FV",
          node: this.infoList.ECF_A_FV_node_string,
          name: "A层周界风手操器",
          manualO: parseInt(this.infoList.ECF_MAN1.AV),
          num1: this.infoList.ECF_MAN1.AV,
          mark1: "AV",
          node1: "ECF_MAN1",
        },
        {
          manualP: parseInt(this.infoList.ECF_B_FV),
          num: this.infoList.ECF_B_FV,
          mark: "ECF_B_FV",
          node: this.infoList.ECF_B_FV_node_string,
          name: "B层周界风手操器",
          manualO: parseInt(this.infoList.ECF_MAN2.AV),
          num1: this.infoList.ECF_MAN2.AV,
          mark1: "AV",
          node1: "ECF_MAN2",
        },
        {
          manualP: parseInt(this.infoList.ECF_C_FV),
          num: this.infoList.ECF_C_FV,
          mark: "ECF_C_FV",
          node: this.infoList.ECF_C_FV_node_string,
          name: "C层周界风手操器",
          manualO: parseInt(this.infoList.ECF_MAN3.AV),
          num1: this.infoList.ECF_MAN3.AV,
          mark1: "AV",
          node1: "ECF_MAN3",
        },
      ];
    } else if (this.numM == 4 && this.titname == "MFL_MANQW") {
      this.dynamicID = "manyManual4";
      this.ManualList = [
        {
          manualP: parseInt(this.infoList.ZQT_1A_FV),
          num: this.infoList.ZQT_1A_FV,
          mark: "ZQT_1A_FV",
          node: this.infoList.ZQT_1A_FV_node_string,
          name: "一级左减温手操器",
          manualO: parseInt(this.infoList.ZQT_MAN1.AV),
          num1: this.infoList.ZQT_MAN1.AV,
          mark1: "AV",
          node1: "ZQT_MAN1",
        },
        {
          manualP: parseInt(this.infoList.ZQT_1B_FV),
          num: this.infoList.ZQT_1B_FV,
          mark: "ZQT_1B_FV",
          node: this.infoList.ZQT_1B_FV_node_string,
          name: "一级右减温手操器",
          manualO: parseInt(this.infoList.ZQT_MAN2.AV),
          num1: this.infoList.ZQT_MAN2.AV,
          mark1: "AV",
          node1: "ZQT_MAN2",
        },
        {
          manualP: parseInt(this.infoList.ZQT_2A_FV),
          num: this.infoList.ZQT_2A_FV,
          mark: "ZQT_2A_FV",
          node: this.infoList.ZQT_2A_FV_node_string,
          name: "二级左减温手操器",
          manualO: parseInt(this.infoList.ZQT_MAN3.AV),
          num1: this.infoList.ZQT_MAN3.AV,
          mark1: "AV",
          node1: "ZQT_MAN3",
        },
        {
          manualP: parseInt(this.infoList.ZQT_2B_FV),
          num: this.infoList.ZQT_2B_FV,
          mark: "ZQT_2B_FV",
          node: this.infoList.ZQT_2B_FV_node_string,
          name: "二级右减温手操器",
          manualO: parseInt(this.infoList.ZQT_MAN4.AV),
          num1: this.infoList.ZQT_MAN4.AV,
          mark1: "AV",
          node1: "ZQT_MAN4",
        },
      ];
    } else if (this.numM == 2 && this.titname == "MFL_MANGS") {
      this.dynamicID = "manyManual2";
      this.ManualList = [
        {
          manualP: parseInt(this.infoList.QBW_A_FV),
          num: this.infoList.QBW_A_FV,
          mark: "QBW_A_FV",
          node: this.infoList.QBW_A_FV_node_string,
          name: "主给水手操器",
          manualO: parseInt(this.infoList.QBW_MAN1.AV),
          num1: this.infoList.QBW_MAN1.AV,
          mark1: "AV",
          node1: "QBW_MAN1",
        },
        {
          manualP: parseInt(this.infoList.QBW_B_FV),
          num: this.infoList.QBW_B_FV,
          mark: "QBW_B_FV",
          node: this.infoList.QBW_B_FV_node_string,
          name: "副给水手操器",
          manualO: parseInt(this.infoList.QBW_MAN2.AV),
          num1: this.infoList.QBW_MAN2.AV,
          mark1: "AV",
          node1: "QBW_MAN2",
        },
      ];
    } else if (this.numM == 2 && this.titname == "MFL_MANSF") {
      this.dynamicID = "manyManual2";
      this.ManualList = [
        {
          manualP: parseInt(this.infoList.YQO_BA_FV),
          num: this.infoList.YQO_BA_FV,
          mark: "YQO_BA_FV",
          node: this.infoList.YQO_BA_FV_node_string,
          name: "甲送风变频手操器",
          manualO: parseInt(this.infoList.YQO_MAN1.AV),
          num1: this.infoList.YQO_MAN1.AV,
          mark1: "AV",
          node1: "YQO_MAN1",
        },
        {
          manualP: parseInt(this.infoList.YQO_BB_FV),
          num: this.infoList.YQO_BB_FV,
          mark: "YQO_BB_FV",
          node: this.infoList.YQO_BB_FV_node_string,
          name: "乙送风变频手操器",
          manualO: parseInt(this.infoList.YQO_MAN2.AV),
          num1: this.infoList.YQO_MAN2.AV,
          mark1: "AV",
          node1: "YQO_MAN2",
        },
      ];
    } else if (this.numM == 2 && this.titname == "MFL_MANYCF") {
      this.dynamicID = "manyManual2";
      this.ManualList = [
        {
          manualP: parseInt(this.infoList.YCF_A_FV),
          num: this.infoList.YCF_A_FV,
          mark: "YCF_A_FV",
          node: this.infoList.YCF_A_FV_node_string,
          name: "甲一次风手操器",
          manualO: parseInt(this.infoList.YCF_MAN1.AV),
          num1: this.infoList.YCF_MAN1.AV,
          mark1: "AV",
          node1: "YCF_MAN1",
        },
        {
          manualP: parseInt(this.infoList.YCF_B_FV),
          num: this.infoList.YCF_B_FV,
          mark: "YCF_B_FV",
          node: this.infoList.YCF_B_FV_node_string,
          name: "乙一次风手操器",
          manualO: parseInt(this.infoList.YCF_MAN2.AV),
          num1: this.infoList.YCF_MAN2.AV,
          mark1: "AV",
          node1: "YCF_MAN2",
        },
      ];
    } else if (this.numM == 2 && this.titname == "MFL_MANYF") {
      this.dynamicID = "manyManual2";
      this.ManualList = [
        {
          manualP: parseInt(this.infoList.LTP_BA_FV),
          num: this.infoList.LTP_BA_FV,
          mark: "LTP_BA_FV",
          node: this.infoList.LTP_BA_FV_node_string,
          name: "甲引风手操器",
          manualO: parseInt(this.infoList.LTP_MAN1.AV),
          num1: this.infoList.LTP_MAN1.AV,
          mark1: "AV",
          node1: "LTP_MAN1",
        },
        {
          manualP: parseInt(this.infoList.LTP_BB_FV),
          num: this.infoList.LTP_BB_FV,
          mark: "LTP_BB_FV",
          node: this.infoList.LTP_BB_FV_node_string,
          name: "乙引风手操器",
          manualO: parseInt(this.infoList.LTP_MAN2.AV),
          num1: this.infoList.LTP_MAN2.AV,
          mark1: "AV",
          node1: "LTP_MAN2",
        },
      ];
    } else if (this.numM == 2 && this.titname == "MFL_TX_MAN") {
      this.dynamicID = "manyManual2";
      this.ManualList = [
        {
          manualP: parseInt(this.infoList.TX_A_FV),
          num: this.infoList.TX_A_FV,
          mark: "TX_A_FV",
          node: this.infoList.TX_A_FV_node_string,
          name: "脱硝A手操器",
          manualO: parseInt(this.infoList.TX_MAN1.AV),
          num1: this.infoList.TX_MAN1.AV,
          mark1: "AV",
          node1: "TX_MAN1",
        },
        {
          manualP: parseInt(this.infoList.TX_B_FV),
          num: this.infoList.TX_B_FV,
          mark: "TX_B_FV",
          node: this.infoList.TX_B_FV_node_string,
          name: "脱硝B手操器",
          manualO: parseInt(this.infoList.TX_MAN2.AV),
          num1: this.infoList.TX_MAN2.AV,
          mark1: "AV",
          node1: "TX_MAN2",
        },
      ];
    } else if (this.numM == 5 && this.titname == "MFL_MANMYP") {
      this.dynamicID = "manyManual";
      this.ManualList = [
        {
          manualP: parseInt(this.infoList.YLF_A_FV),
          num: this.infoList.YLF_A_FV,
          mark: "YLF_A_FV",
          node: this.infoList.YLF_A_FV_node_string,
          name: "A磨油压手操器",
          manualO: parseInt(this.infoList.MYP_MAN1.AV),
          num1: this.infoList.MYP_MAN1.AV,
          mark1: "AV",
          node1: "MYP_MAN1",
        },
        {
          manualP: parseInt(this.infoList.YLF_B_FV),
          num: this.infoList.YLF_B_FV,
          mark: "YLF_B_FV",
          node: this.infoList.YLF_B_FV_node_string,
          name: "B磨油压手操器",
          manualO: parseInt(this.infoList.MYP_MAN2.AV),
          num1: this.infoList.MYP_MAN2.AV,
          mark1: "AV",
          node1: "MYP_MAN2",
        },
        {
          manualP: parseInt(this.infoList.YLF_C_FV),
          num: this.infoList.YLF_C_FV,
          mark: "YLF_C_FV",
          node: this.infoList.YLF_C_FV_node_string,
          name: "C磨油压手操器",
          manualO: parseInt(this.infoList.MYP_MAN3.AV),
          num1: this.infoList.MYP_MAN3.AV,
          mark1: "AV",
          node1: "MYP_MAN3",
        },
        {
          manualP: parseInt(this.infoList.YLF_D_FV),
          num: this.infoList.YLF_D_FV,
          mark: "YLF_D_FV",
          node: this.infoList.YLF_D_FV_node_string,
          name: "D磨油压手操器",
          manualO: parseInt(this.infoList.MYP_MAN4.AV),
          num1: this.infoList.MYP_MAN4.AV,
          mark1: "AV",
          node1: "MYP_MAN4",
        },
        {
          manualP: parseInt(this.infoList.YLF_E_FV),
          num: this.infoList.YLF_E_FV,
          mark: "YLF_E_FV",
          node: this.infoList.YLF_E_FV_node_string,
          name: "E磨油压手操器",
          manualO: parseInt(this.infoList.MYP_MAN5.AV),
          num1: this.infoList.MYP_MAN5.AV,
          mark1: "AV",
          node1: "MYP_MAN5",
        },
      ];
    }
  },
  methods: {
    // 下边按钮增减
    numberAdd(num, nownum, action, left, right, node, i) {
      this.isDisable = false;
      // this.ManualList[i].manualO = right + num
      // left  +=num
      // right  +=num
      let newnum = parseFloat(nownum) + num;
      this.infoList[node].AV = newnum.toFixed(2);
      downmanAction({
        device: this.projectData.project,
        mark: "AV",
        node: node,
        value: this.infoList[node].AV,
        type: "FLOAT",
        action: action,
        cp_type: this.projectData.type,
      })
        .then((res) => {
          if (res.info.res == 1) {
            this.isDisable = true;
            this.ManualList[i].num1 = newnum.toFixed(2);
            this.ManualList[i].manualO = right + num;
          } else {
            this.$message.error("出错了");
            this.isDisable = true;
            this.ManualList[i].num1 = parseFloat(nownum);
            this.ManualList[i].manualO = parseFloat(nownum);
          }
        })
        .catch((err) => {
          this.$message.error("下置失败");
          this.isDisable = true;
          this.infoList[node].AV = parseFloat(nownum);
          this.ManualList[i].manualO = parseFloat(nownum);
        });
    },
    numberjian(num, nownum, action, left, right, node, i) {
      if (right <= 0 || nownum <= 0) {
        return;
      } else {
        this.isDisable = false;
        let newnum1 = parseFloat(nownum) - num;
        this.infoList[node].AV = newnum1.toFixed(2);
        downmanAction({
          device: this.projectData.project,
          mark: "AV",
          node: node,
          value: this.infoList[node].AV,
          type: "FLOAT",
          action: action,
          cp_type: this.projectData.type,
        })
          .then((res) => {
            if (res.info.res == 1) {
              this.isDisable = true;
              this.ManualList[i].num1 = newnum1.toFixed(2);
              this.ManualList[i].manualO = right - num;
            } else {
              this.$message.error("出错了");
              this.isDisable = true;
              this.infoList[node].AV = parseFloat(nownum);
              this.ManualList[i].manualO = parseFloat(nownum);
            }
          })
          .catch((err) => {
            this.$message.error("下置失败");
            this.isDisable = true;
            this.infoList[node].AV = parseFloat(nownum);
            this.ManualList[i].manualO = parseFloat(nownum);
          });
        if (this.infoList[node].AV <= 100) {
          left = parseInt(this.infoList[node].AV);
          if (this.infoList[node].AV <= 0) {
            this.infoList[node].AV = 0.0;
            this.ManualList[i].manualO = 0;
          }
        }
      }
    },
    // 移入移出
    enter(index, val) {
      this.currindex = index;
      this.str = val;
      this.activStyle = "background:#0ef7ff20";
      this.textcololr = "color:#0ef7ff";
    },
    leave(index) {
      this.currindex = index;
      this.activStyle = "background:#02264490";
      this.textcololr = "color:#001a2b";
    },
    // 自动手动
    downZ(val, node) {
      downAction({
        device: this.projectData.project,
        mark: "RM",
        node: node,
        value: val,
        cp_type: this.projectData.type,
      })
        .then((res) => {
          if (res.info.res == 1) {
            this.infoList[node].RM = val;
          } else {
            this.$message.error("出错了");
          }
        })
        .catch((err) => {
          this.$message.error("下置失败");
        });
    },
    closeCompon() {
      this.$emit("sendStatus", "mflmanyManual", false);
    },
    formatTooltip(val) {
      return val;
    },
    formatTooltip1(val) {
      return val;
    },
  },
};
</script>
<style lang="scss" scoped>
.manyManual {
  background: url("~@/assets/images/anquanshezhiditu.png") no-repeat 0 0;
  background-size: 100% 100%;
  cursor: move;
  border-radius: 5px;
  .evaluate-header {
    height: 5vh;
    line-height: 5vh;
    text-align: center;
    color: #0ef7ff;
    font-size: 3vh;
    position: relative;
    border-radius: 5px 5px 0 0;
    padding-top: 1.4vh;
    .evaluate-header-icon {
      width: 2vw;
      height: 2vh;
      position: absolute;
      right: 1vw;
      margin-top: -6vh;
      img {
        cursor: pointer;
      }
    }
  }
  .manual-con {
    .manual_list {
      width: 30vw;
      height: 37vh;
      border-radius: 5px;
      background: url("~@/assets/images/shoucaoqi.png") no-repeat 0 0;
      background-size: 100% 100%;
      .manual-con-tit {
        font-size: 1vw;
        color: #d5fffe;
        margin: 5px 10px;
        letter-spacing: 1px;
        text-align: center;
      }
      .manual-con-p {
        background: #014662;
        border-radius: 6px;
        height: 7vh;
        margin: 5px 10px;
        width: 29vw;
      }
      .manual-con-text {
        font-size: 0.8vw;
        padding: 0 10px;
        color: #6acafb;
        line-height: 20px;
      }
      .manual-con-type {
        padding: 0px 10px;
        font-size: 1vw;
        color: #6acafb;
        width: 29vw;
        .type-p-data {
          display: inline-block;
          background: #001a2a;
          height: 3vh;
          margin-left: 20px;
          color: #1c9bfb;
          width: 10vw;
          line-height: 3vh;
          text-align: center;
          font-size: 1vw;
        }
        .type-o {
          margin-left: auto;
          .type-p-data {
            display: inline-block;
            background: #001a2a;
            height: 3vh;
            margin-right: 20px;
            color: #0de0ac !important;
          }
        }
      }
    }
  }
}
#manyManual {
  width: 95vw;
  height: 88vh;
  .manual-con {
    padding: 0 1vw;
    flex-wrap: wrap;
    margin-top: 2vh;
    .manual_list {
      margin-top: 1vh;
      margin-left: 0.6vw;
    }
  }
}
#manyManual2 {
  width: 64vw;
  height: 46vh;
  .evaluate-header {
    height: 3vh;
    line-height: 3vh;
    padding-top: 0;
    font-size: 2vh;
    .evaluate-header-icon {
      margin-top: -2vh;
      width: 1.5vw;
      height: 2vh;
    }
  }
  .manual-con {
    padding: 0 1vw;
    flex-wrap: wrap;
    margin-top: 2vh;
    .manual_list {
      margin-top: 1vh;
      margin-left: 0.6vw;
      .manual-con-tit {
        font-size: 0.8vw;
      }
    }
  }
}
#manyManual3 {
  width: 95vw;
  height: 46vh;
  .evaluate-header {
    height: 3vh;
    line-height: 3vh;
    padding-top: 0;
    font-size: 2vh;
    .evaluate-header-icon {
      margin-top: -2vh;
      width: 1.5vw;
      height: 2vh;
    }
  }
  .manual-con {
    padding: 0 1vw;
    flex-wrap: wrap;
    margin-top: 2vh;
    .manual_list {
      margin-top: 1vh;
      margin-left: 0.6vw;
      .manual-con-tit {
        font-size: 0.8vw;
      }
    }
  }
}
#manyManual4 {
  width: 64vw;
  height: 88vh;
  .manual-con {
    padding: 0 1vw;
    flex-wrap: wrap;
    margin-top: 2vh;
    .manual_list {
      margin-top: 1vh;
      margin-left: 0.6vw;
      .manual-con-tit {
        font-size: 0.8vw;
      }
    }
  }
}
::v-deep {
  .manual-con-p {
    .el-slider {
      height: 4vh;
    }
    .el-slider__runway {
      height: 1vh;
      background-color: #022537;
      box-shadow: 0px 1px 0px 0px #d4e3e8,
        inset 0px 1px 0px 0px rgba(0, 0, 0, 0.08);
      border-radius: 20px;
      width: 27vw;
      margin-left: 1.5vw;
      .el-slider__bar {
        height: 1vh;
        background-color: #d4e3e8;
        box-shadow: inset 0px 1px 0px 0px rgba(0, 0, 0, 0.08);
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }
      .el-slider__button-wrapper {
        top: -13px;
      }
      @media only screen and (min-width: 1800px) {
        .el-slider__button-wrapper {
          top: -0.9vh !important;
        }
      }
      .el-slider__button {
        width: 2vw;
        height: 2vh;
        background-image: linear-gradient(-45deg, #61f9ff 0%, #02a1dc 65%),
          linear-gradient(-45deg, #207295 0%, #6ff6ff 100%);
        background-blend-mode: normal, normal;
        border-radius: 10px;
        border: none;
        //  margin-left: 1vw;
        //  margin-top: 1vh;
      }
    }
  }
  .manual-con-o {
    .el-slider__bar {
      background-color: #98ecd5 !important;
      box-shadow: inset 0px 1px 0px 0px rgba(0, 0, 0, 0.08) !important;
    }
    .el-slider__button {
      background-image: linear-gradient(-45deg, #61f9ff 0%, #07ae75 65%),
        linear-gradient(-45deg, #207295 0%, #6ff6ff 100%) !important;
      background-blend-mode: normal, normal !important;
    }
  }
  .manual-con-btn {
    justify-content: center;
    margin-top: 10px;

    div {
      padding: 2px 20px;
      border-radius: 3px;
      text-align: center;
      line-height: 20px;
      cursor: pointer;
    }
    .pzd {
      background: #056181;

      color: #fff;
      margin-right: 10px;
    }
    .oactive {
      background: #0ef7ff;
      color: #fff;
    }
  }
  .manual-con-add {
    margin-top: 2vh;
    .add-double-left {
      background: #02264490;
      height: 4vh;
      width: 8vw;
      margin-left: 10px;
      color: #1ba3da;
      cursor: pointer;
      text-align: center;
      line-height: 4vh;
      span {
        color: #001a2b;
        font-size: 2vw;
        display: inline-block;
        height: 4vh;
        line-height: 4vh;
      }
      .el-icon-caret-left2 {
        margin-left: -10px;
      }
      .el-icon-caret-left1 {
        margin-right: -10px;
      }
    }
    .add-double-right {
      margin-right: 10px;
    }
  }

  .add-double-cen {
    flex-grow: 1;
    justify-content: center;
    .add-double-cen-btn {
      background: #02264490;
      height: 4vh;
      width: 5vw;
      margin-left: 20px;
      color: #001a2b;
      cursor: pointer;
      text-align: center;
      span {
        color: #001a2b;
        font-size: 2vw;
        display: inline-block;
        height: 4vh;
        line-height: 4vh;
      }
    }
  }
}
</style>